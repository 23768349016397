<template>
  <div class="card-body">
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        :items="deniedRequests"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sorrt-desc.sync="sortDesc"
        :fit-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        hover
      >
        <template v-slot:cell(action)="row">
          <div class="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              data-caret="false"
              class="text-muted"
              ><b-icon-three-dots></b-icon-three-dots
            ></a>
            <div class="dropdown-menu dropdown-menu-right p-3">
              <button
                @click="sendRequest(row.item.id, 'accept')"
                class="btn text-success"
              >
                Accept
              </button>

              <button
                @click="sendRequest(row.item.id, 'decline')"
                class="btn text-danger"
              >
                Deny
              </button>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/state/store";

export default {
  props: ["deniedRequests"],
  data() {
    return {
      // data table meta
      userId: this.$route.params.id,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "course",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "full_name", sortable: true, label: "Full Name" },
        { key: "username", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Contact" },
        { key: "status", sortable: true, label: "Status" },
        { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      success: null,
      error: null,
      key: null,
      certPDF: null,
    };
  },
  methods: {
    sendRequest(enrollmentId, action) {
      const data = {
        action: action,
        request_id: enrollmentId,
      };
      console.log(data);
      store
        .dispatch("hiring/hireAction", data)
        .then((response) => {
          this.toast({ message: response.status, type: "success" });
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    rows() {
      return this.deniedRequests && this.deniedRequests.length;
    },
  },
  created() {},
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
