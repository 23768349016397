<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="card">
          <!-- Table -->

          <b-tabs content-class="mt-3">
            <b-tab title="All Requests" active>
              <AllRequest :certifiedUsers="certifiedUsers" />
            </b-tab>
            <b-tab title="Accepted Requests">
              <AcceptedRequests :acceptedRequests="acceptedRequests"
            /></b-tab>
            <b-tab title="Pending Requests"
              ><PendingRequests :pendingRequests="pendingRequests"
            /></b-tab>
            <b-tab title="Denied Requests">
              <DeniedRequests :declinedRequests="declinedRequests" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import AllRequest from "./Technician/AllRequests.vue";
import AcceptedRequests from "./Technician/Accepted.vue";
import DeniedRequests from "./Technician/Denied.vue";
import PendingRequests from "./Technician/Pending.vue";

import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  page: {
    title: "Hire",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
    AllRequest,
    AcceptedRequests,
    DeniedRequests,
    PendingRequests,
  },
  data() {
    return {
      // data table meta
      userId: this.$route.params.id,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "course",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "full_name", sortable: true, label: "Full Name" },
        { key: "username", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Contact" },
        { key: "status", sortable: true, label: "Status" },
        { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "Hire",
      items: [
        {
          text: "User",
        },
        {
          text: "Hire",
          active: true,
        },
      ],
      certPDF: null,
      certifiedUsers: [],
      pendingRequests: [],
      acceptedRequests: [],
      declinedRequests: [],
    };
  },
  methods: {
    sendRequest(enrollmentId, action) {
      const data = {
        action: action,
        request_id: enrollmentId,
      };
      console.log(data);
      store
        .dispatch("hiring/hireAction", data)
        .then((response) => {
          this.toast({ message: response.status, type: "success" });
          this.fetchData();
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      store
        .dispatch("hiring/fetchAllTechnicianRequests", this.userId)
        .then((response) => {
          let data = response.data.requests;

          const users = data.map((user) => {
            return {
              id: user.id,
              status: user.status,
              full_name: `${user.recruiter.first_name} ${user.recruiter.other_names} ${user.recruiter.last_name}`,
              username: user.recruiter.email,
              phone: user.recruiter.phone,
              technician_id: user.technician_id,
              recruiter_id: user.recruiter_id,
            };
          });

          for (let i = 0; i < users.length; i++) {
            if (users[i].status == "accepted") {
              this.acceptedRequests.push(users[i]);
            } else if (users[i].status == "pending") {
              this.pendingRequests.push(users[i]);
            } else if (users[i].status == "declined") {
              this.declinedRequests.push(users[i]);
            }
          }

          console.log(response);

          this.certifiedUsers = users;
        });
    },
  },
  computed: {},
  created() {
    this.fetchData();
  },
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
